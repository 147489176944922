<template>
  <div>
    <div class="font-weight-bold text-h6 mb-3">
      {{ $i18n.t("statistics.title") }}
    </div>
    <v-tabs
      active-class="secondary--text"
      slider-color="secondary"
      class="elevation-1 rounded-lg"
    >
      <v-tab
        v-if="$permission.$can('message.statistic', ['user', 'service'])"
        :to="{ name: 'statistic-message' }"
        exact
        ><span class="font-weight-bold">{{ $t("menu.messages") }}</span></v-tab
      >
      <v-tab
        v-if="$permission.$can('ticket.statistic', ['user', 'service'])"
        :to="{ name: 'statistic-ticket' }"
        exact
        ><span class="font-weight-bold">{{ $t("menu.tickets") }}</span></v-tab
      >
    </v-tabs>
    <div class="mt-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>